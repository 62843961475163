.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	/* background-color: #282c34; */
	/* background-color: #b2b3b4; */
	background-color: #fff;
	/* min-height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	/* transition-duration: 0.3s; */
	/* color: white; */
}

.App-header-dark {
	/* background-color: #282c34; */
	/* background-color: #b2b3b4; */
	background-color: #fff;
	/* min-height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	/* color: white; */
	filter: invert(1);
	/* transition-duration: 0.3s; */
}

.App-header-dark .ignoreInvert {
	filter: invert(1);
	transition-duration: 0.3s;
}

.App-header-align-top {
	/* background-color: #282c34; */
	/* background-color: #b2b3b4; */
	background-color: #fff;
	/* min-height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: left;
	font-size: calc(10px + 2vmin);
	/* color: white; */
	/* transition-duration: 0.3s; */
}

.App-header-align-top-dark {
	/* background-color: #282c34; */
	/* background-color: #b2b3b4; */
	background-color: #fff;
	/* min-height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: left;
	font-size: calc(10px + 2vmin);
	/* color: white; */
	filter: invert(1);
	/* transition-duration: 0.3s; */
}

.App-header-align-top-dark .ignoreInvert {
	filter: invert(1);
	transition-delay: 0.01s;
}

.ignoreInvert {
	/* transition-duration: 0.3s !important; */
	transition-delay: 0.01s;
}

.App-header-align-top-dark .url.ignoreInvert,
.App-header-dark .url.ignoreInvert
{
	color: #4477FF
}

.url.ignoreInvert {
	transition-duration: 0.02s;
}

.darkModeToggle {
	width: 100% !important; 
	margin-left: 5px;
}

.App-link {
	color: #61dafb;
}

.success-message {
	transition-duration: 0.4s;
	background-color: #ade4ad;
	border: 1px solid #73b873;
	color: #4a794a;
	padding: 10px;
	border-radius: 5px;
	font-size: 12pt;
	width: 30%;
	position: absolute;
	top: 12%;
	right: 3%;
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
}

.transparent {
	opacity: 0%;
	transition-duration: 0.5s;
}

.not-transparent {
	opacity: 100%;
	transition-duration: 0.5s;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
